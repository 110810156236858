import React, { createContext, useContext, useState, useEffect } from "react";
import { useSessionStorage } from "../../utils/local-storage";
import { Patient } from "../types/patient.type";

interface FormData {
  formId: string | number;
  requestId: number;
  patient: Patient;
  practiceName?: string;
  type?: string;
  isSubmitted?: boolean;
}

const FormContext = createContext<{
  formData: FormData;
  setFormData: React.Dispatch<FormData>;
} | null>(null);

export const FormProvider = ({ children }) => {
  const { getData, updateData } = useSessionStorage("formData");

  const [formData, setFormData] = useState(getData(null));

  useEffect(() => {
    updateData(formData);
  }, [formData, updateData]);

  return (
    <FormContext.Provider value={{ formData, setFormData }}>
      {children}
    </FormContext.Provider>
  );
};

export const useFormContext = () => useContext(FormContext);
