import {
  Alert,
  Box,
  Checkbox,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./form-dashboard-preview.styles.css";
import { grey } from "@mui/material/colors";
import { useState } from "react";
import { useFormContext } from "services/contexts/FormContext";
import { PURCHASE_AGREEMENT_SIGN_REQUEST_TYPE } from "constants/patient-forms";
import { Patient } from "../../services/types/patient.type";

interface FormDashboardPreviewProps {
  form: { external_id: number | string; title: string };
  type?: string;
  patient: Patient;
  practiceName?: string;
  requestId: number;
  isSubmitted: boolean;
}

export default function FormDashboardPreview({
  form,
  type,
  practiceName,
  requestId,
  isSubmitted,
  patient,
}: FormDashboardPreviewProps) {
  const navigate = useNavigate();
  const formContext = useFormContext();

  const [errorSnackBarIsOpened, setErrorSnackBarIsOpened] = useState(false);

  const isPurchaseAgreement = type === PURCHASE_AGREEMENT_SIGN_REQUEST_TYPE;

  const openErrorSnackBar = () => {
    setErrorSnackBarIsOpened(true);
  };

  const closeErrorSnackBar = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setErrorSnackBarIsOpened(false);
  };

  const handleFormClick = () => {
    const formId = form.external_id;

    if (formId && requestId && patient && practiceName) {
      formContext?.setFormData({
        formId,
        requestId,
        patient,
        practiceName,
        type,
        isSubmitted,
      });

      if (!isSubmitted || isPurchaseAgreement) {
        navigate(String(form.external_id));
      }
    } else {
      openErrorSnackBar();
    }
  };

  return (
    <>
      <Snackbar
        open={errorSnackBarIsOpened}
        autoHideDuration={6000}
        onClose={closeErrorSnackBar}
        message=""
      >
        <Alert severity="error">
          Something went wrong. Please, try to reload page or re-login.
        </Alert>
      </Snackbar>
      <Box
        sx={{
          overflow: "hidden",
          transition: "all 0.3s",
          opacity: 1,
          width: "300px",
          flexGrow: "1",
          padding: "5px 14px 5px 5px",
          borderRadius: "5px",

          boxShadow:
            "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
          ...(!isSubmitted || isPurchaseAgreement
            ? {
                backgroundColor: "white",
                "&:hover": {
                  cursor: "pointer",
                  transform: "scale(1.01)",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                },
              }
            : {
                backgroundColor: grey[100],
              }),
        }}
        onClick={handleFormClick}
        className="form-preview animate__animated animate__fadeIn animate__delay-1s"
      >
        <Stack direction="row" alignItems="center">
          <Checkbox disabled checked={isSubmitted} />
          <Typography variant="body2">{form.title}</Typography>
        </Stack>
      </Box>
    </>
  );
}
