import { Button, Fab, Stack, Tooltip } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import JotFormReact from "jotform-react";
import { useCallback, useState } from "react";
import { useFormContext } from "../../services/contexts/FormContext";
import { useFlags } from "launchdarkly-react-client-sdk";
import { PURCHASE_AGREEMENT_AUTH_VIA_FEATURE } from "constants/feature-flags";
import SignaturePicker from "components/signature-picker";
import {
  ONBOARDING_REQUEST_TYPE,
  PURCHASE_AGREEMENT_SIGN_REQUEST_TYPE,
} from "constants/patient-forms";
import callBackend from "utils/call-backend";
import { PATIENT_FORMS_PATH_URL } from "services/hooks/patient-forms/use-patient-forms.hook";
import CircularProgressLoader from "components/loading";
import PreviewFormDocument from "./preview-form-document";
import { openFile } from "utils/common";

const formIframeStyles = {
  minWidth: "100%",
  maxWidth: "100%",
  height: "100vh",
  minHeight: "100vh",
};

interface EmbeddedFormsProps {
  onSubmit: (formId: string | number) => void;
}

const prefillableFormsIds = ["241654987086067", "241676629809067"];

export default function EmbeddedForms({ onSubmit }: EmbeddedFormsProps) {
  const navigate = useNavigate();
  const { practiceName } = useParams();
  const flags = useFlags();
  const isPurchaseAgreementEnabled = flags[PURCHASE_AGREEMENT_AUTH_VIA_FEATURE];

  const formContext = useFormContext();
  const formData = formContext?.formData;

  const navigateToDashboard = useCallback(() => {
    navigate(`/${practiceName}/`);
  }, [practiceName, navigate]);

  const handleSubmit = useCallback(() => {
    formData?.formId && onSubmit(formData?.formId);
    navigateToDashboard();
  }, [navigateToDashboard, formData?.formId, onSubmit]);

  const [isUpdating, setIsUpdating] = useState(false);

  const [showSignModal, setShowSignModalModal] = useState(false);

  const handleSignDocumentModal = useCallback(
    (files: File[]) => {
      const file = files[0];

      const requestData = new FormData();
      requestData.append("signature", file);

      const token = (sessionStorage.getItem("authToken") || "").replaceAll(
        '"',
        "",
      );

      setIsUpdating(true);
      callBackend({
        url: `${PATIENT_FORMS_PATH_URL}${formData?.formId}/`,
        method: "PATCH",
        body: requestData,
        token,
        params: {},
      }).then(() => {
        setIsUpdating(false);
        navigateToDashboard();
      });
    },
    [formData?.formId, navigateToDashboard],
  );

  const [fileUrl, setFileUrl] = useState("");

  const handleAfterPdfDownload = useCallback((url: string) => {
    setFileUrl(url);
  }, []);

  if (
    !formData?.formId ||
    !formData?.requestId ||
    !formData?.patient.fullName ||
    !formData?.practiceName
  ) {
    return null;
  }

  if (isUpdating) {
    return <CircularProgressLoader sx={{ mt: 4 }} />;
  }

  const defaultParams = {
    requestId: formData.requestId.toString(),
    email: formData?.patient.email ?? "",
    full_name: formData?.patient.fullName,
    practice_name: formData?.practiceName,
  };

  const birthdayParts = (formData.patient.birthdate ?? "").split("-");

  const additionalParams = prefillableFormsIds.includes(
    formData.formId.toString(),
  )
    ? {
        email15: formData?.patient.email ?? "",
        "name[first]": formData.patient.first_name,
        "name[last]": formData.patient.last_name,
        "date_of_birth[year]": formData.patient.birthdate
          ? Number(birthdayParts.at(0))
          : null,
        "date_of_birth[month]": formData.patient.birthdate
          ? Number(birthdayParts.at(1))
          : null,
        "date_of_birth[day]": formData.patient.birthdate
          ? Number(birthdayParts.at(2))
          : null,
        "phone_number[full]": formData?.patient?.phone,
        "primary_address[addr_line1]": formData?.patient?.street_address_1,
        "primary_address[addr_line2]": formData?.patient?.street_address_2,
        "primary_address[city]": formData?.patient?.city,
        "primary_address[state]": formData?.patient?.state,
        "primary_address[postal]": formData?.patient?.zip_code,
      }
    : {};

  const params = Object.entries({ ...defaultParams, ...additionalParams })
    .map(([key, value]) =>
      value ? `${key}=${encodeURIComponent(value ?? "")}` : "",
    )
    .filter(Boolean)
    .join("&");

  const formURL = `https://form.jotform.com/${formData?.formId}?${params}`;

  return (
    <>
      <Stack
        direction="row"
        columnGap={3}
        sx={{
          position: "absolute",
          top: "10px",
          left: "10px",
        }}
      >
        <Tooltip title="Go to Dashboard" arrow>
          <Fab onClick={navigateToDashboard}>
            <ArrowBackIcon />
          </Fab>
        </Tooltip>
        {isPurchaseAgreementEnabled &&
          formData.type === PURCHASE_AGREEMENT_SIGN_REQUEST_TYPE &&
          !formData.isSubmitted && (
            <Button
              variant="contained"
              onClick={() => {
                setShowSignModalModal(true);
              }}
            >
              Sign Document
            </Button>
          )}
        {isPurchaseAgreementEnabled &&
          formData.type === PURCHASE_AGREEMENT_SIGN_REQUEST_TYPE &&
          fileUrl && (
            <Button
              variant="contained"
              onClick={() => {
                openFile(fileUrl, `purchase-agreement-${formData.formId}.pdf`);
              }}
            >
              Download
            </Button>
          )}
      </Stack>

      {formData.type === ONBOARDING_REQUEST_TYPE && (
        <JotFormReact
          scrolling="yes"
          style={formIframeStyles}
          formID={formData?.formId}
          onSubmit={handleSubmit}
          title={`Title-${formData?.formId}`}
          id={`JotFormIFrame-${formData?.formId}`}
          formURL={formURL}
        />
      )}

      {isPurchaseAgreementEnabled &&
        formData.type === PURCHASE_AGREEMENT_SIGN_REQUEST_TYPE && (
          <PreviewFormDocument
            formId={formData.formId}
            afterPdfDownload={handleAfterPdfDownload}
          />
        )}

      {showSignModal && (
        <SignaturePicker
          title="Sign Document"
          submitButtonName="Sign Document"
          open={showSignModal}
          onClose={() => {
            setShowSignModalModal(false);
          }}
          onSubmit={handleSignDocumentModal}
          hasInitialImage={false}
        />
      )}
    </>
  );
}
