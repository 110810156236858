import FormDashboardPreview from "../form-dashboard-preview";
import {
  type IntakeFormRequestSubmission,
  IntakeFormSubmissionStatus,
} from "services/types/intake-form-request.type";
import { useMemo } from "react";
import { PatientPracticeType } from "services/types/patient-practice.type";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import PatientFormWrapper from "./patient-form-wrapper.component";
import { ONBOARDING_REQUEST_TYPE } from "constants/patient-forms";
import { Patient } from "../../services/types/patient.type";

interface MainContentFormsProps {
  requestId: number;
  patient: Patient;
  practice: PatientPracticeType | undefined;
  intakeFormRequests: IntakeFormRequestSubmission[] | undefined;
}

export default function MainContentForms(props: MainContentFormsProps) {
  const { intakeFormRequests = [], requestId, practice, patient } = props;

  const { submittedForms, incompleteForms } = useMemo(() => {
    const submittedForms: IntakeFormRequestSubmission[] = [];
    const incompleteForms: IntakeFormRequestSubmission[] = [];

    intakeFormRequests.forEach((formRequest) =>
      formRequest.status === IntakeFormSubmissionStatus.SUBMITTED
        ? submittedForms.push(formRequest)
        : incompleteForms.push(formRequest),
    );

    return { submittedForms, incompleteForms };
  }, [intakeFormRequests]);

  return (
    <Container>
      <Stack direction="row" flexWrap="wrap">
        {!!submittedForms.length && (
          <>
            <Typography variant="body2" fontWeight="bold">
              Completed Forms:{" "}
            </Typography>
            <PatientFormWrapper>
              {submittedForms.map((formRequest) => (
                <FormDashboardPreview
                  key={formRequest.id}
                  patient={patient}
                  practiceName={practice?.name}
                  requestId={requestId}
                  form={formRequest.intake_form}
                  type={ONBOARDING_REQUEST_TYPE}
                  isSubmitted={
                    formRequest.status === IntakeFormSubmissionStatus.SUBMITTED
                  }
                />
              ))}
            </PatientFormWrapper>
          </>
        )}
        {!!incompleteForms.length && (
          <>
            <Typography variant="body2" fontWeight="bold">
              Pending Forms:{" "}
            </Typography>
            <PatientFormWrapper>
              {incompleteForms.map((formRequest) => (
                <FormDashboardPreview
                  key={formRequest.id}
                  patient={patient}
                  practiceName={practice?.name}
                  requestId={requestId}
                  form={formRequest.intake_form}
                  type={ONBOARDING_REQUEST_TYPE}
                  isSubmitted={
                    formRequest.status === IntakeFormSubmissionStatus.SUBMITTED
                  }
                />
              ))}
            </PatientFormWrapper>
          </>
        )}
      </Stack>
    </Container>
  );
}
