import FormDashboardPreview from "../form-dashboard-preview";
import { PatientPracticeType } from "services/types/patient-practice.type";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import PatientFormWrapper from "./patient-form-wrapper.component";
import { PatientPublicFormInstance } from "services/types/patient-form.type";
import { PURCHASE_AGREEMENT_SIGN_REQUEST_TYPE } from "constants/patient-forms";
import { Patient } from "../../services/types/patient.type";

interface MainContentPurchaseAgreementFormsProps {
  requestId: number;
  saleId?: number;
  patient: Patient;
  practice: PatientPracticeType | undefined;
  requests: PatientPublicFormInstance[] | undefined;
}

export default function MainContentPurchaseAgreementForms(
  props: MainContentPurchaseAgreementFormsProps,
) {
  const { requests = [], requestId, patient, practice, saleId } = props;

  const isSubmitted = Boolean(requests?.at(0)?.signature);

  return (
    <Container>
      <Stack direction="row" flexWrap="wrap">
        <Typography variant="body2" fontWeight="bold">
          {isSubmitted ? "Completed" : "Pending"} Forms:{" "}
        </Typography>
        <PatientFormWrapper>
          {requests.map((formRequest) => (
            <FormDashboardPreview
              key={formRequest.id}
              patient={patient}
              practiceName={practice?.name}
              requestId={requestId}
              form={{
                external_id: formRequest.id,
                title: `Purchase Agreement for ${patient.fullName} ${saleId ? ` (#${saleId})` : ""}`,
              }}
              type={PURCHASE_AGREEMENT_SIGN_REQUEST_TYPE}
              isSubmitted={isSubmitted}
            />
          ))}
        </PatientFormWrapper>
      </Stack>
    </Container>
  );
}
